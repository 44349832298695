import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { errorService } from 'services/alert/services';
import { TournamentService } from 'services/api/tournament';

import { dialogStyles } from './styles';

const CancelTournamentDialog = ({ onHandleClose, id, open, onSuccess }) => {
  const styles = dialogStyles();

  const handleSubmit = async () => {
    try {
      await TournamentService.cancelByID(id);
      await onSuccess();
      onHandleClose();
    } catch (e) {
      errorService.sendError(e?.response?.data?.message ?? e.message);
    }
  };

  return (
    <div>
      <Dialog onClose={onHandleClose} className={styles.dialog} open={open}>
        <DialogTitle id="form-dialog-title">
          Вы уверены, что хотите отменить турнир {id}?
        </DialogTitle>
        <DialogContent>
          <DialogActions>
            <Button color="secondary" variant="contained" onClick={onHandleClose}>
              Отмена
            </Button>
            <Button color="primary" variant="contained" onClick={handleSubmit}>
              Подтвердить
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CancelTournamentDialog;
