import React, { useEffect } from 'react';
import { NotificationContainer } from 'react-notifications';

import 'react-notifications/lib/notifications.css';
import { useHistory, useLocation } from 'react-router-dom';
import { Redirect } from 'react-router';

import AlertService from 'services/alert';
import { useAuth } from 'hooks/use-auth';
import { useStores } from 'hooks/use-stores';

export default function Wrapper({ children }) {
  const auth = useAuth();
  const location = useLocation();
  const {countryStore} = useStores()

  useEffect(() => {
    countryStore.getCountries()
  }, [])

  if (!location.pathname.startsWith('/login') && (!auth.token || auth.token === '')) {
    return <Redirect to="/login" />
  }

  return (
    <div>
      {children}
      <AlertService />
      <NotificationContainer />
    </div>
  );
}
