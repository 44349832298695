import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export class SystemAvatarService {
  static async getAll() {
    const response = await api.get(endpoints.systemAvatars);
    return response;
  }
  static async create(payload: Record<string, any>) {
    const response = await api.post(endpoints.systemAvatars, payload);
    return response;
  }
  static async update(payload: Record<string, any>) {
    const response = await api.put(endpoints.systemAvatars, payload);
    return response;
  }
  static async delete(id: string) {
    const response = await api.delete(`${endpoints.systemAvatars}/${id}`);
    return response;
  }
}
