import * as Yup from 'yup';
import { Formik } from 'formik';
import TextField from '@material-ui/core/TextField';
import NumberFormat from 'react-number-format';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Typography, InputLabel, MenuItem, Select, FormControl } from '@material-ui/core';
import { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Checkbox, Box, Chip, Divider, Paper } from '@mui/material';
import * as _ from 'lodash';
import { makeStyles } from '@mui/styles';

import { currencies, paymentProviders, paymentTypeTranslations } from 'constants/payment';
import { emitter } from 'shared/emitter';
import { PaymentMethodService } from 'services/api/payment-method';
import { errorService } from 'services/alert/services';
import UploadImageInput from 'components/UploadImageInput';
import { useStores } from 'hooks/use-stores';

const useStyles = makeStyles({
  formControlLabel: {
    color: 'rgba(0, 0, 0, 0.87)!important',
    fontWeight: '300',
  },
});

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
    />
  );
}

interface IProps {
  onFetch: () => void;
}

export default function CreateOrUpdatePaymentMethod({ onFetch }: IProps) {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>(null);
  const [mode, setMode] = useState('create');
  const [selectAll, setSelectAll] = useState(false);
  const { countryStore } = useStores();
  const classes = useStyles();

  const countriesByIds = useMemo(() => {
    return _.keyBy(countryStore.countries, 'id');
  }, [countryStore.countries]);

  useEffect(() => {
    emitter.on('paymentMethods:createOrUpdateForm:open', (mode: 'create' | 'update', data: any) => {
      setMode(mode);
      setData(data);
      setOpen(true);
    });
    emitter.on('paymentMethods:createOrUpdateForm:close', () => {
      setOpen(false);
      setData(null);
      setMode('create');
    });
  }, []);

  const handleClose = () => {
    emitter.emit('paymentMethods:createOrUpdateForm:close');
  };

  const initialValues = useMemo(() => {
    if (mode === 'create') {
      return {
        name: 'Payment',
        minAmount: 0,
        maxAmount: null,
        exchangeRate: 1,
        currency: '',
        provider: '',
        type: '',
        order: 1,
        iconUrl: '',
        enabled: true,
        bank: '',
        supportedCountries: [],
      };
    }
    return {
      name: data.name,
      minAmount: data.minAmount,
      maxAmount: data.maxAmount,
      exchangeRate: data.exchangeRate,
      currency: data.currency,
      provider: data.provider,
      type: data.type,
      order: data.order,
      iconUrl: data.iconUrl,
      enabled: data.enabled ?? false,
      bank: data?.extra?.bankName ?? '',
      supportedCountries: data?.supportedCountries || [],
    };
  }, [mode]);

  const onCreate = async (data) => {
    const maxAmount = data.maxAmount ? Number(data.maxAmount) : null;
    const newData = {
      ...data,
      minAmount: +data.minAmount,
      maxAmount,
      order: +data.order,
      exchangeRate: +data.exchangeRate,
      type: +data.type,
    };
    try {
      await PaymentMethodService.create(newData);
      await onFetch();
      handleClose();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const onUpdate = async (updatedData) => {
    const maxAmount = updatedData.maxAmount ? Number(updatedData.maxAmount) : null;
    const newData = {
      ...updatedData,
      minAmount: +updatedData.minAmount,
      maxAmount,
      order: +updatedData.order,
      exchangeRate: +updatedData.exchangeRate,
      type: +updatedData.type,
    };
    try {
      await PaymentMethodService.updateById(data._id, newData);
      await onFetch();
      handleClose();
    } catch (e) {
      errorService.sendError('Произошла какая то ошибка' + e.message);
    }
  };

  const onSubmit = async (values) => {
    if (mode === 'create') {
      await onCreate(values);
    } else {
      await onUpdate(values);
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Создать метод платежа</DialogTitle>
        <DialogContent style={{ minWidth: 450 }}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={Yup.object().shape({
              name: Yup.string(),
              minAmount: Yup.number().required('Введите Сумму'),
              maxAmount: Yup.number()
                .nullable(true)
                .transform((_, val) => (val === Number(val) ? val : null)),
              exchangeRate: Yup.number().required('Введите Обменный Курс'),
              currency: Yup.string().required('Выберите Курс'),
              provider: Yup.string().required('Выберите Провайдер'),
              type: Yup.string().required('Выберите тип'),
              order: Yup.string().required('Введите порядок'),
              iconUrl: Yup.string().required('Введите ссылку на иконку'),
            })}
            onSubmit={onSubmit}
            render={({
              values,
              errors,
              handleSubmit,
              handleChange,
              isSubmitting,
              setFieldValue,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <FormControl component="fieldset" fullWidth>
                    <Typography variant={'body1'}>Тип</Typography>
                    <RadioGroup name="type" value={Number(values.type)} onChange={handleChange}>
                      {Object.entries(paymentTypeTranslations).map(([value, label]) => (
                        <FormControlLabel value={Number(value)} control={<Radio />} label={label} />
                      ))}
                    </RadioGroup>
                    <br />
                    <Typography variant={'body1'}>Провайдер</Typography>
                    <RadioGroup name="provider" value={values.provider} onChange={handleChange}>
                      {Object.entries(paymentProviders).map(([value, label]) => (
                        <FormControlLabel value={value} control={<Radio />} label={label} />
                      ))}
                    </RadioGroup>
                    <br />
                    <Typography variant={'body1'}>Курс</Typography>
                    <RadioGroup name="currency" value={values.currency} onChange={handleChange}>
                      {Object.entries(currencies).map(([value, label]) => (
                        <FormControlLabel value={value} control={<Radio />} label={label} />
                      ))}
                    </RadioGroup>
                    <TextField
                      onChange={handleChange}
                      error={!!errors.name}
                      name="name"
                      label="Название"
                      value={values.name}
                      placeholder="Введите название"
                      fullWidth
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.bank}
                      name="bank"
                      label="Имя банка для cassy"
                      value={values.bank}
                      placeholder="Введите имя банка(пример: tinkoff)"
                      fullWidth
                    />
                    <UploadImageInput
                      onChange={(newValue: string) => {
                        setFieldValue('iconUrl', newValue);
                      }}
                      image={values.iconUrl}
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.exchangeRate}
                      name="exchangeRate"
                      autoFocus
                      label="Обменный Курс"
                      value={values.exchangeRate}
                      placeholder="Введите обменный курс"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.minAmount}
                      name="minAmount"
                      autoFocus
                      label="Минимальная сумма"
                      value={values.minAmount}
                      placeholder="Введите минимальную сумму"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.maxAmount}
                      name="maxAmount"
                      autoFocus
                      label="Максимальная сумма"
                      value={values.maxAmount}
                      placeholder="Введите максимальную сумму"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />
                    <TextField
                      onChange={handleChange}
                      error={!!errors.order}
                      name="order"
                      autoFocus
                      label="Порядок"
                      value={values.order}
                      placeholder="Введите порядок"
                      fullWidth
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(values.enabled)}
                          name="enabled"
                          onChange={(e, checked) => {
                            setFieldValue('enabled', checked);
                          }}
                          inputProps={{ 'aria-label': 'controlled' }}
                        />
                      }
                      label={<span className={classes.formControlLabel}>Включено?</span>}
                    />

                    <Autocomplete
                      multiple
                      id="tags-filled"
                      options={countryStore.countries.map((option) => option.id)}
                      value={values.supportedCountries}
                      onChange={(e, newValues) => {
                        setFieldValue('supportedCountries', newValues);
                      }}
                      getOptionLabel={(option) => {
                        return countriesByIds[option]?.name;
                      }}
                      filterSelectedOptions
                      renderTags={(value: readonly string[], getTagProps) => {
                        const numTags = value.length;
                        const limitTags = 10;
                        return (
                          <>
                            {value.slice(0, 5).map((option: string, index: number) => {
                              const { key, ...tagProps } = getTagProps({ index });
                              return (
                                <Chip
                                  variant="outlined"
                                  label={countriesByIds[option]?.name}
                                  key={key}
                                  {...tagProps}
                                />
                              );
                            })}
                            {numTags > limitTags && ` +${numTags - limitTags}`}
                          </>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Страны"
                          placeholder="Страны"
                        />
                      )}
                      PaperComponent={(paperProps) => {
                        const { children, ...restPaperProps } = paperProps;
                        return (
                          <Paper {...restPaperProps}>
                            <Box onMouseDown={(e) => e.preventDefault()} pl={1.5} py={0.5}>
                              <FormControlLabel
                                onClick={(e) => {
                                  e.preventDefault();
                                  setSelectAll((prev) => {
                                    if (!prev)
                                      setFieldValue(
                                        'supportedCountries',
                                        countryStore.countries.map((c) => c.id),
                                      );
                                    else setFieldValue('supportedCountries', []);
                                    return !prev;
                                  });
                                }}
                                label="Выбрать все"
                                control={<Checkbox id="select-all-checkbox" checked={selectAll} />}
                              />
                            </Box>
                            <Divider />
                            {children}
                          </Paper>
                        );
                      }}
                    />
                  </FormControl>

                  <DialogActions>
                    <Button onClick={() => handleClose()} color="secondary" variant="contained">
                      Отмена
                    </Button>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => handleSubmit()}
                      disabled={isSubmitting}
                    >
                      {mode === 'create' ? 'Создать' : 'Обновить'}
                    </Button>
                  </DialogActions>
                </form>
              );
            }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
}
