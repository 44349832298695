import endpoints from 'api/endpoints';
import { api } from 'api/rest';

export interface ICreateRatingHistoryParams {
  userId: string;
  amount: number;
  gameId?: string;
  description: string;
}

export class RatingHistoryService {
  static async findAll(params: Record<string, any>) {
    const response = await api.get(endpoints.ratingHistories, { params });
    return response;
  }
  static async create(data: ICreateRatingHistoryParams) {
    const response = await api.post(endpoints.ratingHistories, data);
    return response;
  }
}
