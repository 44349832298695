import React, { useState, useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import PaymentsTable from 'components/PaymentsTable';
import { RejectPayoutForm } from 'components/RejectPayout';
import { PaymentService } from 'services/api/payment';
import { errorService, infoService } from 'services/alert/services';
import ConfirmDialog from 'components/ConfirmDialog';
import { UserProfileDialog } from 'components/UserProfile';

const styles = {
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
};

const useStyles = makeStyles(styles as any);

const Payments = ({ type, title }) => {
  const classes = useStyles();
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isUserDialogOpen, setUserDialogOpen] = useState(false);

  const onUserClick = useCallback(async (rowData) => {
    setSelectedUserId(rowData._id);
    setUserDialogOpen(true);
  }, []);

  return (
    <div>
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>{title}</p>
            </CardHeader>
            <CardBody>
              <UserProfileDialog
                id={selectedUserId}
                open={isUserDialogOpen}
                onHandleClose={() => {
                  setUserDialogOpen(false);
                }}
                onVerificationUpdate={() => {}}
              />
              <PaymentsTable title={title} type={type} onUserClick={onUserClick} />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default Payments;
