import React from 'react';
import { Grid, Select, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MenuItem from '@material-ui/core/MenuItem';
import * as Yup from 'yup';
import { Formik } from 'formik';

import { UserService } from 'services/api/user';
import { errorService } from 'services/alert/services';

import { rejectionReasons, rejectionReasonsLabels } from './constant';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    flexDirection: 'column',
  },
  form: {
    width: 500,
    margin: '0 0',
  },
  cellItem: {
    width: '100%',
    margin: '0 0 15px',
  },
  errorText: {
    width: '100%',
    color: '#f44336',
  },
  loaderContainer: {
    width: 450,
  },
  padding: { padding: 4 },
  bold: { fontWeight: 'bolder' },
});

export const UserRejectVerificationDialog = ({ id, open, onClose, onSubmit }) => {
  const classes = useStyles();

  const onHandleSubmit = async (values) => {
    try {
      const newValues = {
        ...values,
        verified: false,
        isSystemReason: Boolean(rejectionReasons[values.rejectReason]),
      };
      await UserService.rejectVerification(id, newValues);
    } catch (e) {
      errorService.sendError(e.message);
    } finally {
      onSubmit();
    }
  };

  return (
    <div>
      <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Отклонить верификацию</DialogTitle>
        <DialogContent>
          <Grid container className={classes.fullWidth}>
            <Grid item className={classes.fullWidth}>
              <Grid container className={classes.fullWidth}>
                <Formik
                  initialValues={{ rejectReason: '' }}
                  enableReinitialize
                  validationSchema={Yup.object().shape({
                    rejectReason: Yup.string().required('Введите причину отказа'),
                  })}
                  onSubmit={onHandleSubmit}
                  render={({ values, errors, handleSubmit, handleChange, handleBlur }) => {
                    return (
                      <form onSubmit={handleSubmit} className={classes.form}>
                        <Grid container direction="column" justify="center" alignItems="center">
                          <Grid item xs={12} className={classes.cellItem}>
                            <Select
                              fullWidth
                              label="Причина"
                              placeholder="Причина отклона"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={!!errors.rejectReason}
                              name="rejectReason"
                              value={values.rejectReason}
                            >
                              {Object.entries(rejectionReasonsLabels).map(([value, label], i) => {
                                return (
                                  <MenuItem key={i} value={value}>
                                    {label}
                                  </MenuItem>
                                );
                              })}
                              <MenuItem value="">Другая причина</MenuItem>
                            </Select>
                          </Grid>
                          <Grid item xs={12} className={classes.errorText}>
                            {errors.rejectReason}
                          </Grid>

                          {!rejectionReasons[values.rejectReason] && (
                            <Grid item xs={12} className={classes.cellItem}>
                              <TextField
                                fullWidth
                                multiline
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={!!errors.rejectReason}
                                name="rejectReason"
                                value={values.rejectReason}
                                label="Причина отклона"
                              />
                            </Grid>
                          )}
                        </Grid>
                        <DialogActions>
                          <Button color="primary" onClick={onClose}>
                            Закрыть
                          </Button>
                          <Button color="primary" type="submit" variant="contained">
                            Отклонить
                          </Button>
                        </DialogActions>
                      </form>
                    );
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </div>
  );
};
