import { AppConfig } from './config';
export {appColors} from "./config"

export const appConfig = new AppConfig(
  {
    client: 'https://control.dicechess.dev',
    server: null,

    mainClient: 'https://dicechess.dev',
    mainServer: null,
  },
  {
    client: 'https://control.dicechess.one',
    server: null,

    mainClient: 'https://dicechess.games',
    mainServer: null,
  },
);
