export enum EGameFen {
  FEN_NORMAL = 1,
  FEN_960 = 2,
}
export enum EGameType {
  CASH = 1,
  MATCH = 2,
  TOURNAMENT = 3,
}
export enum EGameState {
  MOVE = 1,
  WAIT = 2,
  FINISHED = 4,
}
