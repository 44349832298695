import React, { useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import Card from 'components/Card/Card.js';
import CardHeader from 'components/Card/CardHeader.js';
import CardBody from 'components/Card/CardBody.js';
import GamesTable from 'components/GamesTable';
import { UserProfileDialog } from 'components/UserProfile';
import { useAPI } from 'hooks/use-api';
import endpoints from 'api/endpoints';

const useStyles = makeStyles({
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: 300,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
});

export default function ActiveGames() {
  const params = useParams();

  const { userId } = params;

  const classes = useStyles();

  return (
    <div>
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <p className={classes.cardTitleWhite}>Активные игры</p>
            </CardHeader>
            <CardBody>
              <GamesTable isActiveGames />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
