import React, { useEffect, useRef, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AddIcon from '@mui/icons-material/Add';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { IconButton } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import dayjs from 'dayjs';

import GridItem from 'components/Grid/GridItem.js';
import GridContainer from 'components/Grid/GridContainer.js';
import { useAuthAPI } from 'hooks/use-auth-api';
import endpoints from 'api/endpoints';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';
import { mapFenTypeToText } from 'domain/game';
import { mapFenTypeMapper, mapTypeMapper, mapTypeToText } from 'domain/game/game.mapper';
import { ObjectHelper } from 'shared/helpers/ObjectHelper';
import { EGameState } from 'domain/game/game.types';
import { GameService } from 'services/api/game';
import { errorService } from 'services/alert/services';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold',
  },
};
// @ts-ignore
const useStyles = makeStyles(styles);

export const convertIntoArray = (values) => {
  const convertedArray = Object.entries(values).map(([key, value]: [string, string]) => ({
    text: value.replace(/([A-Z])/g, ' $1').trim(),
    value: key,
  }));
  return convertedArray;
};

const validateFilter = (filter) => {
  if (Array.isArray(filter) && !filter[0] && !filter[1]) {
    return false;
  }
  return true;
};

export const getFilters = (columnFilters) => {
  return columnFilters.reduce((acc, item) => {
    if (validateFilter(item.value)) acc[item.id] = item.value;
    return acc;
  }, {});
};

const gameTypeLookupFilters = ObjectHelper.objectToArray(mapTypeMapper, ([key, value]) => ({
  text: value,
  value: key,
}));
const gameFenTypeLookupFilters = ObjectHelper.objectToArray(mapFenTypeMapper, ([key, value]) => ({
  text: value,
  value: key,
}));

const MIN_PAGE_SIZE = 30;

interface IGamesTableProps {
  isActiveGames: boolean;
}

const GamesTable = ({ isActiveGames }: IGamesTableProps) => {
  const params = useParams();

  const { userId } = params;
  const location = useLocation();

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Игрок 1',
        accessorKey: 'players.0',
        Cell: ({ cell }) => {
          return cell.getValue()?.email;
        },
        enableColumnFilterModes: false,
        // enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'Игрок 2',
        accessorKey: 'players.1',
        Cell: ({ cell }) => {
          return cell.getValue()?.email;
        },
        enableColumnFilterModes: false,
        // enableColumnFilter: false,
        enableSorting: false,
      },
      {
        header: 'Тип',
        accessorKey: 'type',
        filterVariant: 'multi-select',
        filterSelectOptions: gameTypeLookupFilters,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return mapTypeToText(value);
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Тип фена',
        accessorKey: 'fenType',
        filterVariant: 'multi-select',
        filterSelectOptions: gameFenTypeLookupFilters,
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return mapFenTypeToText(value);
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Время начала',
        accessorKey: 'startedAt',
        type: 'date',
        filterVariant: 'equals',
        sortingFn: 'datetime',
        Cell: ({ cell }) => {
          return dayjs(cell.getValue()).format('HH:mm:ss DD-MM-YYYY');
        },
        muiTableHeadCellFilterTextFieldProps: { InputProps: { inputProps: { type: 'date' } } },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'Количество ходов',
        accessorKey: 'moveCount',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
      {
        header: 'ID турнира или матча',
        accessorKey: 'relativeId',
        Cell: ({ cell }) => {
          return cell.getValue();
        },
        enableColumnFilterModes: false,
        enableSorting: false,
      },
    ],
    [],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce(
      (acc, column) => {
        const searchQueryFilter = searchQueryFilters[column.accessorKey];
        if (searchQueryFilter) {
          acc.push({
            id: column.accessorKey,
            value: searchQueryFilter,
          });
        }
        return acc;
      },
      [] as { id: string; value: any }[],
    );
  }, [searchQueryFilters]);

  const isPersonalGames = userId && userId !== '0';

  const classes = useStyles();
  const request = useAuthAPI();

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MIN_PAGE_SIZE,
  });
  const [cursorPagination, setCursorPagination] = useState({
    cursor: null,
    prevCursor: null,
    nextCursor: null,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const getGames = async () => {
    try {
      setIsLoading(true);

      const sort = sorting[0] as any;
      const orderQuery = sort
        ? { orderBy: sort?.id, orderDirection: sort?.desc ? 'desc' : 'asc' }
        : {};

      const filterByUserId = isPersonalGames ? { userId } : {};
      const filters = getFilters(columnFilters);

      if (isActiveGames) {
        filters.state = [EGameState.MOVE, EGameState.WAIT];
      }

      URLHelper.replaceSearchParams(qs.stringify({ ...filters, pageSize: pagination.pageSize }));

      const { data: remoteData } = await request(endpoints.games, {
        limit: pagination.pageSize,
        cursor: cursorPagination.cursor,
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setData(remoteData.games);
      setCursorPagination({
        ...cursorPagination,
        prevCursor: remoteData.prevCursor,
        nextCursor: remoteData.nextCursor,
      });
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const onFirstPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: null,
    });
  };

  const onPrevPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: cursorPagination.prevCursor,
    });
  };
  const onNextPageClick = () => {
    setCursorPagination({
      ...cursorPagination,
      cursor: cursorPagination.nextCursor,
    });
  };

  useEffect(() => {
    getGames();
  }, [debouncedColumnFilters, pagination.pageSize, sorting, cursorPagination.cursor]);

  const finishGame = async (id: string) => {
    try {
      const response = await GameService.finishGame(id);
      getGames();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  return (
    <div>
      <GridContainer>
        {/* @ts-ignore */}
        <GridItem xs={12} sm={12} md={12}>
          <MaterialReactTable
            // @ts-ignore
            columns={columns}
            data={data}
            getRowId={(row: any) => row._id}
            initialState={{
              showColumnFilters: true,
            }}
            enableMultiSort={false}
            manualFiltering
            manualPagination
            manualSorting
            enableGlobalFilter={false}
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: 'error',
                    children: 'Error loading data',
                  }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onSortingChange={setSorting}
            rowCount={-1}
            enableColumnFilterModes
            localization={MRT_Localization_RU}
            enableToolbarInternalActions
            enableRowActions={isActiveGames}
            renderRowActions={({ row }) => (
              <>
                <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                  <IconButton onClick={() => finishGame(row.original._id)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </>
            )}
            muiTablePaginationProps={() => ({
              component: () => (
                <div>
                  <IconButton aria-label="delete" onClick={onFirstPageClick}>
                    <FirstPageIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    disabled={!cursorPagination.prevCursor}
                    onClick={onPrevPageClick}
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    disabled={!cursorPagination.nextCursor}
                    onClick={onNextPageClick}
                  >
                    <ChevronRightIcon />
                  </IconButton>
                </div>
              ),
            })}
            state={{
              columnFilters,
              isLoading,
              showAlertBanner: isError,
              sorting,
            }}
            renderTopToolbar={({ table }) => {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <MRT_ShowHideColumnsButton table={table} />
                    <MRT_FullScreenToggleButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                  </Box>
                </Box>
              );
            }}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default GamesTable;
