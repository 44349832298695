import endpoints from 'api/endpoints';
import { api } from 'api/rest';

interface IGetCountriesResponse {
  countries: {
    id: string;
    code: string;
    name: string;
  }[];
}

export class CountryService {
  static async findAll() {
    const response = await api.get<IGetCountriesResponse>(endpoints.country);
    return response;
  }
}
