import { isDevClient } from 'constants/isDevClient';

export const endpointsRoot = process.env.REACT_APP_API_ENDPOINT || '';
const endpoints = {
    login: `${endpointsRoot}/api/v1/auth/login`,
    user: `${endpointsRoot}/api/v1/user`,
    users: `${endpointsRoot}/api/v1/user`,
    admins: `${endpointsRoot}/api/v1/user/admins`,
    adminSupport: `${endpointsRoot}/api/v1/user/support`,
    stats: `${endpointsRoot}/api/v1/stats`,
    gamesByUser: `${endpointsRoot}/api/v1/game/by-user`,
    games: `${endpointsRoot}/api/v1/game`,
    game: `${endpointsRoot}/api/v1/game`,
    languages: `${endpointsRoot}/api/v1/languages`,
    transactions: `${endpointsRoot}/api/v1/transaction`,
    ranks: `${endpointsRoot}/api/v1/ranks`,
    variables: `${endpointsRoot}/api/v1/variables`,
    configs: `${endpointsRoot}/api/v1/config`,
    rakeLevels: `${endpointsRoot}/api/v1/rake-levels`,
    payments: `${endpointsRoot}/api/v1/payment`,
    rejectPayment: `${endpointsRoot}/api/v1/payment/reject-withdrawal`,
    approvePayment: `${endpointsRoot}/api/v1/payment/approve-withdrawal`,
    exportTranslations: `${endpointsRoot}/api/v1/languages/export`,
    tournaments: `${endpointsRoot}/api/v1/tournaments`,
    versions: `${endpointsRoot}/api/v1/versions`,
    supportChats: `${endpointsRoot}/api/v1/chats/support`,
    createSupportChat: `${endpointsRoot}/api/v1/chats/support-chat`,
    supportMessage: `${endpointsRoot}/api/v1/chats/support/message`,
    referralPrograms: `${endpointsRoot}/api/v1/referral-programs`,
    staticBlock: `${endpointsRoot}/api/3/static-block`,
    recreatePayout: `${endpointsRoot}/api/v1/payments/recreate-payout`,
    paymentMethods: `${endpointsRoot}/api/v1/payment-methods`,
    pushNotifications: `${endpointsRoot}/api/v1/push-notifications`,
    notifications: `${endpointsRoot}/api/v1/notifications`,
    banners: `${endpointsRoot}/api/v1/banners`,
    files: `${endpointsRoot}/api/v1/files`,
    importJackStats: `${endpointsRoot}/api/v1/transaction/import-jack-stats`,
    externalGames: `${endpointsRoot}/api/v1/external-games`,
    systemAvatars: `${endpointsRoot}/api/3/system-avatars`,
    country: `${endpointsRoot}/api/v1/country`,
    transactionsStats: `${endpointsRoot}/api/v1/transaction/stats`,
    stickerPacks: `${endpointsRoot}/api/v1/sticker-packs`,
    externalGameProviders: `${endpointsRoot}/api/v1/external-game-providers`,
    ratingHistories: `${endpointsRoot}/api/v1/rating-histories`,
    statistics: `${endpointsRoot}/api/v1/transaction/stats`,
};

const mainApiUrl = isDevClient ? 'https://dicechess.dev' : 'https://dicechess.games';

export const mainApiEndpoints = {
    supportMedia: `${mainApiUrl}/support-attachments`,
};

export default endpoints;