export const STATUS_MESSAGES = {
  awaiting: 'В ожидании',
  not_ready: 'Не готова',
  in_progress: 'Идет игра',
  finished: 'Завершена',
};

export const CAUSE_MESSAGES = {
  timeout: 'Таймаут',
  capture: 'Мат',
  resign: 'Сдался',
  inactivity: 'Ушел из игры',
};

export const BALANCE_TYPE_MESSAGES = {
  1: "REAL",
  2: "COINS",
  3: "PLAY",
  4: "REFERRAL_BONUS",
  5: "REFERRAL_REVENUE_SHARE",
  6: "RAKE_BACK",
};
