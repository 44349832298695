import React, { useState, useEffect, useContext, createContext } from 'react';
import _ from 'lodash';

import { doPost, doGet } from 'api/rest';
import endpoints from 'api/endpoints';
import { useStateWithLocalStorage } from 'hooks/use-state-with-local-storage';
import { errorService } from 'services/alert/services';

const authContext = createContext();

export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};

function useProvideAuth() {
  const [token, setToken] = useStateWithLocalStorage({
    key: 'auth_token',
    defaultValue: '',
  });

  const [user, setUser] = useStateWithLocalStorage({
    key: 'user',
    defaultValue: {},
  });

  const [isLoading, setLoading] = useState(true);

  const signin = async (email, password) => {
    setLoading(true);

    const response = await doPost({
      url: endpoints.login,
      params: { username: email, password },
    });

    setLoading(false);
    const { result, error } = response;

    if (error) {
      errorService.sendError('Логин или пароль не верный!');
    } else {
      if (result.accessToken) {
        const { user, accessToken } = result;
        setToken(accessToken);
        setUser(user);
        return true;
      }
    }
    return false;
  };

  const signout = () => {
    setUser(false);
    setToken('');
  };

  return {
    user,
    token,
    isLoading,
    signin,
    signout,
  };
}
