import React, { useEffect, useState, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import {
  MaterialReactTable,
  MRT_ShowHideColumnsButton,
  MRT_FullScreenToggleButton,
  MRT_ToggleDensePaddingButton,
  MRT_ToggleFiltersButton,
} from 'material-react-table';
import qs from 'qs';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AddIcon from '@mui/icons-material/Add';
import { MRT_Localization_RU } from 'material-react-table/locales/ru';
import { IconButton } from '@mui/material';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CloseIcon from '@mui/icons-material/Close';
import _ from 'lodash';
import DoneIcon from '@mui/icons-material/Done';

import GridContainer from 'components/Grid/GridContainer.js';
import CardBody from 'components/Card/CardBody.js';
import { useAuthAPI } from 'hooks/use-auth-api';
import { URLHelper } from 'shared/helpers/URLHelper';
import { useDebounce } from 'hooks/use-debounce';
import { PaymentService } from 'services/api/payment';
import { errorService } from 'services/alert/services';
import { RejectPayoutForm } from 'components/RejectPayout';
import { Nullable } from 'shared/types';

const styles = {
  increaseBalance: {
    color: '#2eb92e',
  },
  decreaseBalance: {
    color: '#e01919',
  },
  takingReservedBalance: {
    color: '#707070',
  },
  cardCategoryWhite: {
    color: 'rgba(255,255,255,.62)',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    marginBottom: '0',
  },
  cardTitleWhite: {
    color: '#FFFFFF',
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300' as any,
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
  },
  balance: {
    marginRight: '10px',
    fontWeight: 'bold' as any,
  },
  paymentDetailsWrapper: {
    width: '100%',
    paddingLeft: '10%',
    flexDirection: 'column' as 'column',
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent: 'flex-start',
  },
};

const useStyles = makeStyles(styles);

export const convertIntoArray = (values: Record<string, any>) => {
  const convertedArray = Object.entries(values).map(([key, value]) => ({
    text: value.replace(/([A-Z])/g, ' $1').trim(),
    value: key,
  }));
  return convertedArray;
};

const validateFilter = (filter) => {
  if (Array.isArray(filter) && !filter[0] && !filter[1]) {
    return false;
  }
  return true;
};

export const getFilters = (columnFilters) => {
  return columnFilters.reduce((acc, item) => {
    if (validateFilter(item.value)) acc[item.id] = item.value;
    return acc;
  }, {});
};

const MIN_PAGE_SIZE = 30;

interface IPaymentsTableProps {
  title: string;
  type: 'TOP_UP' | 'WITHDRAW' | 'WITHDRAW_REQUEST';
  onUserClick: (id: string) => void;
}

const paymentStatusArray = [
  { text: 'Создано', value: 1 },
  { text: 'Ошибка при создании', value: 2 },
  { text: 'Ожидает одобрения', value: 3 },
  { text: 'Обработка', value: 4 },
  { text: 'Обработано', value: 5 },
  { text: 'Отменено', value: 6 },
  { text: 'Возвращено', value: 7 },
  { text: 'Ошибка', value: 8 },
  { text: 'Отклонено', value: 9 },
];

const paymentStatusByValue = paymentStatusArray.reduce((acc, item) => {
  acc[item.value] = item.text;

  return acc;
}, {});

const PaymentsTable = ({ type, onUserClick }: IPaymentsTableProps) => {
  const params = useParams();

  const { userId } = params;
  const location = useLocation();
  const classes = useStyles();

  const searchQueryFilters = qs.parse(location.search.substring(1));

  const columns = useMemo(
    () => [
      {
        header: 'ID',
        accessorKey: '_id',
        enableColumnFilterModes: false,
      },
      {
        header: 'Пользователь',
        accessorKey: 'user',
        enableColumnFilterModes: false,
        Cell: ({ cell }) => {
          return cell.getValue()?.email;
        },
      },
      {
        header: 'Сумма',
        accessorKey: 'amount',
        enableColumnFilterModes: false,
      },
      {
        header: 'Статус',
        accessorKey: 'status',
        filterVariant: 'multi-select',
        filterSelectOptions: paymentStatusArray,
        Cell: ({ cell }) => <div>{paymentStatusByValue[cell.getValue()]}</div>,
        enableColumnFilterModes: false,
      },
      {
        header: 'Провайдер',
        accessorKey: 'provider',
        enableColumnFilterModes: false,
      },
      {
        header: 'Курс',
        accessorKey: 'convertedCurrency',
        enableColumnFilterModes: false,
        enableFiltering: false,
      },
      {
        header: 'Сконвертированная сумма',
        accessorKey: 'convertedAmount',
        enableColumnFilterModes: false,
        enableFiltering: false,
      },
    ],
    [],
  );

  const defaultFilters = useMemo(() => {
    return columns.reduce((acc, column) => {
      const searchQueryFilter = searchQueryFilters[column.accessorKey];
      if (searchQueryFilter) {
        acc.push({
          id: column.accessorKey,
          value: searchQueryFilter,
        });
      }
      return acc;
    }, [] as any[]);
  }, [searchQueryFilters]);

  const isPersonalPayments = userId && userId !== '0';

  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const [openRejectPayoutForm, setOpenRejectPayoutForm] = useState(false);
  const [selectedPaymentId, setSelectedPaymentId] = useState<Nullable<string>>(null);

  const [columnFilters, setColumnFilters] = useState(defaultFilters);
  const [sorting, setSorting] = useState<any[]>([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: MIN_PAGE_SIZE,
  });

  const debouncedColumnFilters = useDebounce(columnFilters, 700);

  const onApprove = async (id: string) => {
    try {
      await PaymentService.approve(id);
      getPayments();
    } catch (e) {
      errorService.sendError(e.message);
    }
  };

  const getPayments = async () => {
    try {
      setIsLoading(true);

      const sort = sorting[0];
      const orderQuery = sort
        ? { orderBy: sort?.id, orderDirection: sort?.desc ? 'desc' : 'asc' }
        : {};

      const filterByUserId = isPersonalPayments ? { userId } : {};
      const filters = getFilters(columnFilters);

      URLHelper.replaceSearchParams(
        qs.stringify({ ...filters, page: pagination.pageIndex, pageSize: pagination.pageSize }),
      );

      const { data: remoteData } = await PaymentService.findAll({
        limit: pagination.pageSize,
        offset: pagination.pageIndex * pagination.pageSize,
        type,
        ...orderQuery,
        ...filters,
        ...filterByUserId,
      });
      setData(remoteData.payments);
      setRowCount(remoteData.totalCount);
      setIsError(false);
    } catch (e) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPayments();
  }, [debouncedColumnFilters, pagination.pageIndex, pagination.pageSize, sorting]);

  const onReject = (id: string) => {
    setSelectedPaymentId(id);
    setOpenRejectPayoutForm(true);
  };

  return (
    <div>
      <RejectPayoutForm
        open={openRejectPayoutForm}
        paymentNo={selectedPaymentId}
        onHandleClose={() => {
          setOpenRejectPayoutForm(false);
        }}
        onSuccess={() => {
          getPayments();
        }}
      />
      <GridContainer>
        {/* @ts-ignore */}
        <CardBody style={{ overflow: 'hidden' }}>
          <MaterialReactTable
            // @ts-ignore
            columns={columns}
            data={data}
            getRowId={(row: any) => row._id}
            initialState={{
              showColumnFilters: true,
            }}
            enableMultiSort={false}
            manualFiltering
            manualPagination
            manualSorting
            enableRowActions
            enableGlobalFilter={false}
            muiToolbarAlertBannerProps={
              isError
                ? {
                    color: 'error',
                    children: 'Error loading data',
                  }
                : undefined
            }
            onColumnFiltersChange={setColumnFilters}
            onPaginationChange={setPagination}
            onSortingChange={setSorting}
            rowCount={rowCount}
            enableColumnFilterModes
            localization={MRT_Localization_RU}
            enableToolbarInternalActions
            state={{
              columnFilters,
              isLoading,
              pagination,
              showAlertBanner: isError,
              sorting,
            }}
            renderRowActions={({ row }) => (
              <>
                {type === 'WITHDRAW_REQUEST' && (
                  <Box sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                    <IconButton onClick={() => onApprove(row.original._id)}>
                      <DoneIcon />
                    </IconButton>
                    <IconButton onClick={() => onReject(row.original._id)}>
                      <CloseIcon />
                    </IconButton>
                  </Box>
                )}
              </>
            )}
            renderDetailPanel={({ row }) => (
              <Box className={classes.paymentDetailsWrapper}>
                {_.size(row.original.details) === 0 && <span>Пусто</span>}
                {_.map(row.original.details as Record<string, any>, (value, key) => {
                  return (
                    <li>
                      {key}: {value}
                    </li>
                  );
                })}
              </Box>
            )}
            renderTopToolbar={({ table }) => {
              return (
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Box>
                    <MRT_ShowHideColumnsButton table={table} />
                    <MRT_FullScreenToggleButton table={table} />
                    <MRT_ToggleDensePaddingButton table={table} />
                    <MRT_ToggleFiltersButton table={table} />
                  </Box>
                </Box>
              );
            }}
          />
        </CardBody>
      </GridContainer>
    </div>
  );
};

export default PaymentsTable;
